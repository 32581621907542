export const stockTickers = [
  // Technology Stocks
  'AAPL',
  'GOOGL',
  'MSFT',
  'TSLA',
  'NVDA',
  'META',
  'AMZN',
  'INTC',
  'ORCL',
  'ADBE',

  // Financial Stocks
  'JPM',
  'BAC',
  'GS',
  'C',
  'WFC',
  'MS',
  'AXP',
  'BLK',
  'V',
  'MA',
  'PYPL',

  // Consumer Goods Stocks
  'KO',
  'PEP',
  'PG',
  'NKE',
  'SBUX',
  'MCD',
  'WMT',
  'TGT',
  'COST',
  'CL',

  // Energy Stocks
  'XOM',
  'CVX',
  'COP',
  'OXY',
  'PSX',
  'SLB',
  'HAL',
  'BP',
  'TOT',
  'RDS.A',

  // Healthcare Stocks
  'JNJ',
  'PFE',
  'MRK',
  'UNH',
  'LLY',
  'BMY',
  'CVS',
  'ABT',
  'GILD',
  'MDT',

  // Industrial Stocks
  'BA',
  'HON',
  'MMM',
  'GE',
  'CAT',
  'UPS',
  'FDX',
  'LMT',
  'RTX',
  'DE',

  // Real Estate Stocks
  'SPG',
  'AMT',
  'CCI',
  'PLD',
  'O',
  'PSA',
  'WELL',
  'EQR',
  'AVB',
  'VTR',

  // Telecommunications Stocks
  'T',
  'VZ',
  'TMUS',
  'CMCSA',
  'CHTR',
  'DISH',
  'NFLX',
  'DISCA',

  // Broad Market ETFs
  'SPY',
  'VOO',
  'IVV',
  'VTI',
  'QQQ',
  'DIA',

  // International Market ETFs
  'VEU',
  'EFA',
  'VXUS',
  'EWJ',
  'FXI',

  // Sector-Specific ETFs
  'XLF',
  'XLK',
  'XLE',
  'XLV',
  'XLY',
  'XLP',
  'XLI',
  'XLU',
  'XLC',

  // Bond & Fixed Income ETFs
  'BND',
  'AGG',
  'TLT',
  'LQD',
  'HYG',

  // Commodity ETFs
  'GLD',
  'SLV',
  'USO',
  'DBA',

  // Real Estate ETFs
  'VNQ',
  'IYR',
  'SCHH',

  // Dividend & Income-Focused ETFs
  'VYM',
  'SCHD',
  'DVY',
  'SDY',

  // Growth & Value ETFs
  'IWF',
  'IWD',
  'VUG',
  'VTV',

  // Small-Cap & Mid-Cap ETFs
  'IJR',
  'IJH',
  'VB',
  'VO',

  // Thematic ETFs
  'ARKK',
  'ARKG',
  'TAN',
  'PBW',
  'KWEB',
];
