import mixpanel from 'mixpanel-browser';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

if (MIXPANEL_TOKEN) {
  mixpanel.init(MIXPANEL_TOKEN);
} else {
  console.warn('No mixpanel token found');
}

interface EventProperties {
  [key: string]: unknown;
}

interface UserProperties {
  $email?: string;
  $name?: string;
  [key: string]: string | number | boolean | null | undefined;
}

interface MixpanelActions {
  identify: (id: string) => void;
  alias: (id: string) => void;
  track: (name: string, props?: EventProperties) => void;
  people: {
    set: (props: UserProperties) => void;
  };
}

// If Mixpanel token is available, define actual actions
const realActions: MixpanelActions = {
  identify: (id: string): void => {
    mixpanel.identify(id);
  },
  alias: (id: string): void => {
    mixpanel.alias(id);
  },
  track: (name: string, props?: EventProperties): void => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props: UserProperties): void => {
      mixpanel.people.set(props);
    },
  },
};

const emptyActions: MixpanelActions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  identify: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  alias: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  track: (): void => {},
  people: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    set: (): void => {},
  },
};
export const Mixpanel: MixpanelActions = MIXPANEL_TOKEN
  ? realActions
  : emptyActions;
