import { StockPrices } from '../types';

export const getCorrelation = (
  stockPrices: StockPrices,
  events: Date[]
): number => {
  const { sortedPrices, sortedPriceDates } = stockPrices;
  const n = Math.min(sortedPrices.length, events.length);

  if (n === 0) return 0; // No data available for correlation

  // Helper function to find the closest price index to an event date
  const findClosestDateIndex = (targetDate: Date): number => {
    let closestIndex = 0;
    let smallestDifference = Infinity;
    for (let i = 0; i < sortedPriceDates.length; i++) {
      const difference = Math.abs(
        sortedPriceDates[i].getTime() - targetDate.getTime()
      );
      if (difference < smallestDifference) {
        smallestDifference = difference;
        closestIndex = i;
      }
    }
    return closestIndex;
  };

  // Collect price changes corresponding to events
  const priceChanges = [];
  for (let i = 0; i < events.length; i++) {
    const closestIndex = findClosestDateIndex(events[i]);
    if (closestIndex < sortedPrices.length - 1) {
      // Ensure there's a following price for a valid change
      const priceChange =
        sortedPrices[closestIndex + 1] - sortedPrices[closestIndex];
      priceChanges.push(priceChange);
    }
  }

  // Calculate the means of priceChanges and event times
  const meanPriceChange =
    priceChanges.reduce((acc, val) => acc + val, 0) / priceChanges.length;
  const meanEventTime =
    events.reduce((acc, val) => acc + val.getTime(), 0) / events.length;

  // Calculate Pearson correlation components
  let numerator = 0;
  let denominatorPrice = 0;
  let denominatorEvent = 0;

  for (let i = 0; i < priceChanges.length; i++) {
    const priceDiff = priceChanges[i] - meanPriceChange;
    const eventDiff = events[i].getTime() - meanEventTime;

    numerator += priceDiff * eventDiff;
    denominatorPrice += priceDiff ** 2;
    denominatorEvent += eventDiff ** 2;
  }

  // Compute Pearson correlation coefficient
  const denominator = Math.sqrt(denominatorPrice * denominatorEvent);
  return denominator === 0 ? 0 : numerator / denominator;
};
