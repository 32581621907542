import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from '../services/Mixpanel';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateToTimeline = () => {
    Mixpanel.track('navigate_to_timeline');
    navigate('/timeline');
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-r from-blue-900 via-purple-700 to-indigo-900 text-white overflow-hidden">
      {/* Hero Section */}
      <section className="w-full py-24 px-6 sm:px-12 md:px-20 flex flex-col md:flex-row items-center text-center md:text-left relative z-10">
        <div className="md:w-1/2 space-y-6">
          <h1 className="text-5xl md:text-6xl font-extrabold leading-tight tracking-tight">
            Profit from the past
          </h1>
          <p className="mt-4 text-lg md:text-xl text-gray-300 max-w-2xl mx-auto md:mx-0">
            Our AI dives into millions of news articles, webpages and historical
            records to reveal hidden patterns, uncover market connections, and
            deliver actionable insights for smarter investments.
          </p>
          <button
            onClick={handleNavigateToTimeline}
            className="mt-8 px-8 py-4 bg-white text-blue-900 font-semibold rounded-full shadow-lg hover:shadow-xl hover:scale-105 transition-transform duration-300"
          >
            Try It For Free
          </button>
        </div>

        <div className="md:w-1/2 mt-10 md:mt-0">
          <img
            src="images/hero-image.png"
            alt="Hero illustration showing stock prices and event analysis"
            className="max-w-full h-auto rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
          />
        </div>
      </section>

      {/* How It Works */}
      <section className="w-full py-20 px-6 sm:px-12 md:px-20 bg-white text-gray-900 rounded-t-3xl">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-bold mb-10 text-center">How It Works</h2>
          <div className="grid md:grid-cols-3 gap-10">
            {/* Step 1 */}
            <div className="bg-gray-50 shadow-lg rounded-xl p-6 hover:scale-105 transition-transform duration-300">
              <div className="flex justify-center mb-6">
                <img
                  src="images/event-search.png"
                  alt="Event Search"
                  className="w-32 h-auto"
                />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 text-center">
                Describe a scenario
              </h3>
              <p className="text-gray-600 mt-2 text-center">
                Describe a complex scenario, like &quot;riots in the EU that
                lasted more than 2 months.&quot; Our AI will sift through
                millions of news articles and historical records to uncover
                every related event, connection, and consequence.
              </p>
            </div>

            {/* Step 2 */}
            <div className="bg-gray-50 shadow-lg rounded-xl p-6 hover:scale-105 transition-transform duration-300">
              <div className="flex justify-center mb-6">
                <img
                  src="images/timeline.png"
                  alt="Timeline"
                  className="w-32 h-auto"
                />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 text-center">
                Analyze the Timeline
              </h3>
              <p className="text-gray-600 mt-2 text-center">
                See how stock prices responded each time similar events occurred
                in the past.
              </p>
            </div>

            {/* Step 3 */}
            <div className="bg-gray-50 shadow-lg rounded-xl p-6 hover:scale-105 transition-transform duration-300">
              <div className="flex justify-center mb-6">
                <img
                  src="images/analysis.png"
                  alt="Analysis"
                  className="w-32 h-auto"
                />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 text-center">
                Make Smarter Investments
              </h3>
              <p className="text-gray-600 mt-2 text-center">
                Use historical insights to guide your strategies and make
                informed trading decisions.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="w-full py-20 px-6 sm:px-12 md:px-20 bg-gradient-to-r from-blue-900 via-purple-700 to-indigo-900 text-white">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">
            Why Use Our AI for Stock Analysis?
          </h2>
          <div className="grid md:grid-cols-3 gap-10">
            {/* Benefit 1 */}
            <div>
              <h3 className="text-2xl font-semibold mb-4">
                Discover Hidden Trends
              </h3>
              <p className="text-gray-300">
                Find patterns in stock movements linked to specific events.
              </p>
            </div>

            {/* Benefit 2 */}
            <div>
              <h3 className="text-2xl font-semibold mb-4">
                Make Confident Decisions
              </h3>
              <p className="text-gray-300">
                Use data-backed insights to strengthen your trading strategies.
              </p>
            </div>

            {/* Benefit 3 */}
            <div>
              <h3 className="text-2xl font-semibold mb-4">
                Act Faster with Data
              </h3>
              <p className="text-gray-300">
                Our AI saves time by analyzing historical data for actionable
                insights.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="w-full py-24 px-6 sm:px-12 md:px-20 bg-white text-center">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-5xl font-bold text-gray-900">
            Ready to Transform Your Trading Strategy?
          </h2>
          <p className="text-gray-600 text-lg mt-4">
            Discover the power of historical patterns and predictive insights.
          </p>
          <button
            onClick={handleNavigateToTimeline}
            className="mt-8 px-10 py-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold rounded-full shadow-lg hover:shadow-xl hover:scale-105 transition-transform duration-300"
          >
            Try It For Free
          </button>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
