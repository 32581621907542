import { StockPrices, HistoricalEvent } from '../types';

// Helper function to find the closest date's index in a sorted array of dates
const findClosestDateIndex = (targetDate: Date, dates: Date[]): number => {
  let closestIndex = 0;
  let smallestDifference = Math.abs(dates[0].getTime() - targetDate.getTime());

  for (let i = 1; i < dates.length; i++) {
    const difference = Math.abs(dates[i].getTime() - targetDate.getTime());
    if (difference < smallestDifference) {
      smallestDifference = difference;
      closestIndex = i;
    }
  }

  return closestIndex;
};

export const getAverageChangeAfterEvents = (
  stockPrices: StockPrices,
  events: HistoricalEvent[]
): number => {
  const { sortedPriceDates, sortedPrices } = stockPrices;
  let totalChange = 0;
  let validEventCount = 0;

  events.forEach((event) => {
    const eventDate = new Date(event.date);
    const dayAfterDate = new Date(eventDate);
    dayAfterDate.setDate(eventDate.getDate() + 1);

    // Find closest indices for eventDate and dayAfterDate
    const eventDateIndex = findClosestDateIndex(eventDate, sortedPriceDates);
    const dayAfterDateIndex = findClosestDateIndex(
      dayAfterDate,
      sortedPriceDates
    );

    // Ensure we have a valid day after the event in the array
    if (
      eventDateIndex !== -1 &&
      dayAfterDateIndex !== -1 &&
      dayAfterDateIndex > eventDateIndex
    ) {
      const eventPrice = sortedPrices[eventDateIndex];
      const dayAfterPrice = sortedPrices[dayAfterDateIndex];
      const change = ((dayAfterPrice - eventPrice) / eventPrice) * 100;
      totalChange += change;
      validEventCount++;
    }
  });

  return validEventCount > 0 ? totalChange / validEventCount : 0;
};
