import { GetTimelineRequest, GetTimelineResponse } from '../types';

export const generateEvents = async (
  query: GetTimelineRequest
): Promise<GetTimelineResponse> => {
  const response = await fetch(
    'https://europe-west2-solulu-prod.cloudfunctions.net/getTimeline',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch timeline events: ${response.statusText}`);
  }

  const data = (await response.json()) as GetTimelineResponse;

  const eventsWithDates = data.events.map((event) => ({
    ...event,
    date: new Date(event.date),
  }));

  return { events: eventsWithDates };
};
