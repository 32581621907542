import React, { useState, useEffect } from 'react';
import { Mixpanel } from '../../services/Mixpanel';

interface StockSelectorCardProps {
  selectedStock: string;
  setSelectedStock: (stock: string) => void;
  stockOptions: string[]; // Array of stock options
}

const StockSelectorCard: React.FC<StockSelectorCardProps> = ({
  selectedStock,
  setSelectedStock,
  stockOptions,
}) => {
  const [localInput, setLocalInput] = useState<string>(selectedStock); // Local state for the input
  const [filteredOptions, setFilteredOptions] =
    useState<string[]>(stockOptions);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

  // Sync the local input with the parent state
  useEffect(() => {
    setLocalInput(selectedStock);
  }, [selectedStock]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();
    setLocalInput(value);

    // Filter the stock options
    const matches = stockOptions.filter((stock) =>
      stock.toUpperCase().startsWith(value)
    );
    setFilteredOptions(matches);

    setShowSuggestions(matches.length > 0);
  };

  const handleOptionClick = (stock: string) => {
    setLocalInput(stock); // Update the input field
    setSelectedStock(stock); // Update parent state
    Mixpanel.track('selected_stock', { stock: stock });
    setShowSuggestions(false); // Hide the suggestions dropdown
  };

  const handleBlur = (e: React.FocusEvent) => {
    // Check if blur is caused by clicking inside the dropdown
    if (
      e.relatedTarget &&
      (e.relatedTarget as HTMLElement).classList.contains('suggestion-item')
    ) {
      return; // Don't close the dropdown
    }
    setShowSuggestions(false); // Hide dropdown
  };

  return (
    <div
      className="bg-white shadow-xl rounded-2xl p-6 transform transition-transform hover:scale-[1.02]"
      style={{ minHeight: '200px' }}
    >
      <h3 className="text-2xl font-semibold mb-4 text-gray-800 tracking-tight">
        Select a Stock
      </h3>
      <div className="relative">
        {/* Input Field */}
        <input
          type="text"
          value={localInput}
          onChange={handleInputChange}
          onFocus={() => setShowSuggestions(true)}
          onBlur={handleBlur}
          placeholder="Enter stock symbol (e.g., AAPL)"
          className="block w-full px-4 py-2 text-gray-700 bg-gray-100 border border-transparent rounded-full focus:ring-2 focus:ring-indigo-400 focus:border-indigo-500 focus:outline-none"
        />

        {/* Suggestions Dropdown */}
        {showSuggestions && (
          <ul
            className="absolute z-10 bg-white border border-gray-300 rounded-md w-full mt-2 max-h-40 overflow-y-auto shadow-lg"
            onBlur={handleBlur}
          >
            {filteredOptions.map((stock) => (
              <li
                key={stock}
                tabIndex={0} // Make suggestion focusable
                onClick={() => handleOptionClick(stock)}
                className="px-4 py-2 cursor-pointer hover:bg-indigo-100 suggestion-item"
              >
                {stock}
              </li>
            ))}
            {filteredOptions.length === 0 && (
              <li className="px-4 py-2 text-gray-500">No matches found</li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default StockSelectorCard;
