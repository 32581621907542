import React, { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/16/solid';

interface CorrelationScoreCardProps {
  correlationScore: number | null;
}

const CorrelationScoreCard: React.FC<CorrelationScoreCardProps> = ({
  correlationScore,
}) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="bg-white shadow-xl rounded-2xl p-8 transform transition-transform hover:scale-[1.02]">
      <h3 className="text-2xl font-semibold mb-6 text-gray-800 tracking-tight">
        pearson correlation score
      </h3>
      {correlationScore !== null ? (
        <>
          <div className="text-3xl font-extrabold text-blue-600">
            {correlationScore.toFixed(2)}
          </div>
          <button
            onClick={() => setShowInfo(!showInfo)}
            className="mt-4 text-blue-600 hover:text-blue-800 flex items-center space-x-1"
          >
            <InformationCircleIcon className="h-5 w-5" />
            <span>What does this mean?</span>
          </button>
          {showInfo && (
            <div className="text-gray-500 text-sm mt-4 text-center">
              A score of <strong>1</strong> means prices rise with events.
              <br />
              <strong>0</strong> means no relationship.
              <br />
              <strong>-1</strong> means prices fall with events.
            </div>
          )}
        </>
      ) : (
        <div className="text-gray-500">Calculating...</div>
      )}
    </div>
  );
};

export default CorrelationScoreCard;
