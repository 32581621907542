import React from 'react';

interface AverageChangeCardProps {
  averageChange: number | null;
}

const AverageChangeCard: React.FC<AverageChangeCardProps> = ({
  averageChange,
}) => (
  <div className="bg-white shadow-xl rounded-2xl p-8 transform transition-transform hover:scale-[1.02]">
    <h3 className="text-2xl font-semibold mb-6 text-gray-800 tracking-tight">
      average change day after event
    </h3>
    {averageChange !== null ? (
      <div className="text-3xl font-extrabold text-purple-600">
        {averageChange.toFixed(2)}%
      </div>
    ) : (
      <div className="text-gray-500">Calculating...</div>
    )}
  </div>
);

export default AverageChangeCard;
