import React, { useState, useEffect } from 'react';
import { BellIcon } from '@heroicons/react/24/outline';
import HypothesisCard from './cards/HypothesisCard';
import StockSelectorCard from './cards/StockSelectorCard';
import StockPriceGraph from './cards/StockPriceGraph';
import GainsCard from './cards/GainsCard';
import AverageChangeCard from './cards/AverageChangeCard';
import CorrelationScoreCard from './cards/CorrelationScoreCard';
import Timeline from './Timeline';
import { getStockPrices } from '../services/getStockPrices';
import { HistoricalEvent, StockPrices } from '../types';
import { getCorrelation } from '../services/getCorrelation';
import { getAverageChangeAfterEvents } from '../services/getAverageChangeAfterEvents';
import { stockTickers } from '../services/stockTickers';
import mixpanel from 'mixpanel-browser';

const Dashboard: React.FC = () => {
  const [hypothesis, setHypothesis] = useState<string>('');
  const [events, setEvents] = useState<HistoricalEvent[]>([]);
  const [selectedStock, setSelectedStock] = useState<string>('AAPL');
  const [stockPrices, setStockPrices] = useState<StockPrices | undefined>();
  const [benchmarkPrices, setBenchmarkPrices] = useState<
    StockPrices | undefined
  >();
  const [correlationScore, setCorrelationScore] = useState<number | null>(null);
  const [averageChange, setAverageChange] = useState<number | null>(null);
  const [isNotifyPopupOpen, setIsNotifyPopupOpen] = useState(false);
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    const fetchBenchmarkPrices = async () => {
      try {
        const benchmark = await getStockPrices('VOO');
        setBenchmarkPrices(benchmark);
      } catch (error) {
        console.error('Failed to fetch benchmark prices:', error);
      }
    };
    fetchBenchmarkPrices();
  }, []);

  useEffect(() => {
    const fetchStockPrices = async () => {
      try {
        const prices = await getStockPrices(selectedStock);
        setStockPrices(prices);

        if (events.length && prices) {
          const eventDates = events.map((event) => new Date(event.date));
          setCorrelationScore(getCorrelation(prices, eventDates));
          setAverageChange(getAverageChangeAfterEvents(prices, events));
        }
      } catch (error) {
        console.error('Failed to fetch stock prices:', error);
      }
    };
    fetchStockPrices();
  }, [selectedStock, events]);

  const handleNotifyClick = () => {
    setIsNotifyPopupOpen(true);
  };

  const handleEmailSubmit = () => {
    if (email) {
      mixpanel.track('notify_alert_email_submission_clicked', {
        email: email,
        stock: selectedStock,
        query: hypothesis,
      });
      alert(
        'Thanks for your interest! We’ll notify you when this feature is ready.'
      );
      setIsNotifyPopupOpen(false);
      setEmail('');
    } else {
      alert('Please enter a valid email address.');
    }
  };

  return (
    <div className="container mx-auto py-8 px-4 md:px-6">
      {/* Hypothesis and Stock Selector */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div className="md:col-span-2">
          <HypothesisCard
            hypothesis={hypothesis}
            setHypothesis={setHypothesis}
            events={events}
            setEvents={setEvents}
          />
        </div>
        <div className="flex flex-col items-stretch">
          <div className="flex-grow">
            <StockSelectorCard
              selectedStock={selectedStock}
              setSelectedStock={setSelectedStock}
              stockOptions={stockTickers}
            />
          </div>
          <button
            onClick={handleNotifyClick}
            className="mt-4 bg-gradient-to-r from-green-500 to-teal-500 text-white font-semibold rounded-full py-2 px-4 flex items-center justify-center gap-2 shadow-md transform transition-transform duration-300 hover:scale-105 hover:from-blue-600 hover:to-purple-600"
          >
            <BellIcon className="h-5 w-5" />
            Notify me if it happens
          </button>
        </div>
      </div>

      {/* Main Dashboard */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          {events.length > 0 && stockPrices && benchmarkPrices && (
            <StockPriceGraph
              stockPrices={stockPrices}
              historicalEvents={events}
            />
          )}
        </div>
        <div className="space-y-6">
          {events.length > 0 && stockPrices && benchmarkPrices && (
            <>
              <GainsCard
                stockPrices={stockPrices}
                benchmarkStockPrices={benchmarkPrices}
                events={events}
                initialDollarAmount={500}
              />
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <AverageChangeCard averageChange={averageChange} />
                <CorrelationScoreCard correlationScore={correlationScore} />
              </div>
            </>
          )}
        </div>
      </div>

      {events.length > 0 && (
        <div className="mt-8">
          <Timeline events={events} />
        </div>
      )}

      {/* Notify Popup */}
      {isNotifyPopupOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-xl shadow-2xl max-w-md w-full text-center">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              Coming Soon!
            </h2>
            <p className="text-gray-600 mb-4 text-sm">
              We are working on this feature. Enter your email below, and we’ll
              notify you as soon as it is ready! (We promise we will not spam
              you)
            </p>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full border rounded-lg px-4 py-2 mb-4 focus:ring-2 focus:ring-blue-500 focus:outline-none text-sm"
            />
            <button
              onClick={handleEmailSubmit}
              className="bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold rounded-full py-2 px-6 shadow-md transform transition-transform duration-300 hover:scale-105 hover:from-blue-600 hover:to-purple-600 w-full"
            >
              Submit
            </button>
            <button
              onClick={() => setIsNotifyPopupOpen(false)}
              className="mt-4 text-sm text-gray-500 underline hover:text-gray-700 transition-all"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
