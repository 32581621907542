import React from 'react';

interface LoadingSpinnerProps {
  size?: number; // Size of the spinner (default 64px)
  color?: string; // Color of the spinner (default blue)
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 64,
  color = 'blue-500',
}) => (
  <div className="flex items-center justify-center min-h-screen">
    <div
      className={`border-4 border-${color} border-t-transparent border-solid rounded-full animate-spin`}
      style={{
        width: size,
        height: size,
        borderWidth: size / 16, // Proportional border width
      }}
    ></div>
  </div>
);

export default LoadingSpinner;
