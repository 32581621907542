import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { HistoricalEvent, StockPrices } from '../../types';

interface StockPriceGraphProps {
  stockPrices: StockPrices;
  historicalEvents: HistoricalEvent[];
}

const StockPriceGraph: React.FC<StockPriceGraphProps> = ({
  stockPrices,
  historicalEvents,
}) => {
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const dates = stockPrices.sortedPriceDates.map((date) =>
      date.toLocaleDateString('en-CA')
    );
    const prices = stockPrices.sortedPrices;
    // Prepare mark lines for historical events with tooltip descriptions only
    const eventLines = historicalEvents.map((event) => ({
      xAxis: event.date.toLocaleDateString('en-CA'), // Position the line at the event date
      lineStyle: { color: '#FF6347', type: 'dashed' }, // Custom color and style for event lines
      label: {
        show: false, // Hides any label on the line
      },
      tooltip: {
        show: true,
        formatter: event.title,
        backgroundColor: '#333',
        borderColor: '#ddd',
        textStyle: { color: '#fff' },
      },
    }));

    setChartOptions({
      tooltip: {
        trigger: 'axis',
        formatter: '{b}: ${c}',
        backgroundColor: '#333',
        borderColor: '#ddd',
        textStyle: {
          color: '#fff',
        },
      },
      xAxis: {
        type: 'category',
        data: dates,
        axisLine: {
          lineStyle: { color: '#ccc' },
        },
        axisLabel: {
          formatter: (value: string) => value.substring(5), // Shows only "MM-DD"
          color: '#888',
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: { color: '#ccc' },
        },
        axisLabel: {
          formatter: '${value}',
          color: '#888',
        },
      },
      series: [
        {
          data: prices,
          type: 'line',
          smooth: true,
          lineStyle: {
            color: '#4a90e2',
            width: 3,
          },
          itemStyle: {
            color: '#4a90e2',
            borderColor: '#fff',
            borderWidth: 2,
          },
          areaStyle: {
            color: 'rgba(74, 144, 226, 0.2)',
          },
          markLine: {
            data: eventLines,
            tooltip: {
              trigger: 'item',
            },
          },
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          xAxisIndex: 0,
          start: 0,
          end: 100, // Start with the full view
          handleIcon: 'M10.7,11.9h-1.4v-1.4h1.4V11.9z', // Custom zoom handle
          handleSize: '80%',
          handleStyle: {
            color: '#333',
            borderColor: '#ddd',
          },
        },
        {
          type: 'inside',
          xAxisIndex: 0,
          start: 0,
          end: 100, // Full zoom range initially
        },
      ],
      grid: {
        left: '3%',
        right: '3%',
        bottom: '10%',
        containLabel: true,
      },
    });
  }, [stockPrices, historicalEvents]);

  return (
    <div className="bg-white shadow-xl rounded-2xl p-8 transform transition-transform hover:scale-[1.02]">
      <h3 className="text-2xl font-semibold mb-6 text-gray-800 tracking-tight">
        Stock price
      </h3>
      <ReactEcharts
        option={chartOptions}
        style={{ height: '400px', width: '100%' }}
      />
    </div>
  );
};

export default StockPriceGraph;
