import { GetConsequencesRequest, GetConsequencesResponse } from '../types';

export const getConsequences = async (
  query: GetConsequencesRequest
): Promise<GetConsequencesResponse> => {
  const response = await fetch(
    'https://europe-west2-solulu-prod.cloudfunctions.net/getConsequences',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch timeline events: ${response.statusText}`);
  }

  return (await response.json()) as GetConsequencesResponse;
};
